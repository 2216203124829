import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { Structure } from "@hiyllo/ux/structure";
import { Typography } from "@hiyllo/ux/typography";
import { Link } from "react-router-dom";

const Container = styled("div", ({$theme}) => ({
    padding: 50,
    backgroundColor: $theme.background1,
    height: "calc(100% - 100px)",
    overflowY: "auto",
    color: $theme.foreground,
    fontFamily: "hiyllo"
}));

const Title = styled("div", ({$theme}) => ({
    fontFamily: "hiyllo",
    fontSize: 37.5,
    fontWeight: "bold",
    color: $theme.foreground,
}));

const ArticleDate = styled("div", ({$theme}) => ({
    color: $theme.foregroundInactive,
    fontSize: 15,
}));

export const BlogListPage = React.memo(function BlogListPage() {
    return (
        <Container>
            <Structure.Row>
                <img
                    src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                    style={{ height: 40 }}
                    alt=""
                />
                <Title>
                    Hiyllo Blog
                </Title>
            </Structure.Row>
            <div style={{ height: 10 }}/>
            <Structure.LColumn10>
                <Link to="/blog/js-api">
                    <div>
                        <Typography.SubHeader>
                            Introducing the Hiyllo JavaScript API
                        </Typography.SubHeader>
                        <ArticleDate>
                            Feb 9th, 2025
                        </ArticleDate>
                    </div>
                </Link>
                <div>
                    That's all the articles we've written so far. Check back later :)
                </div>
            </Structure.LColumn10>
        </Container>
    );
});