import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { Structure } from "@hiyllo/ux/structure";
import { Typography } from "@hiyllo/ux/typography";
import { Table, TableRow, TableCell } from "@hiyllo/ux/table";
import { InlineLink } from "@hiyllo/ux/inline-link";

const Container = styled("div", ({$theme}) => ({
    padding: 50,
    backgroundColor: $theme.background1,
    height: "calc(100% - 100px)",
    overflowY: "auto",
    color: $theme.foreground,
    fontFamily: "hiyllo"
}));

const Title = styled("div", ({$theme}) => ({
    fontFamily: "hiyllo",
    fontSize: 37.5,
    fontWeight: "bold",
    color: $theme.foreground,
}));

export const SecurityPage = React.memo(function BlogListPage() {
    return (
        <Container>
            <Structure.Row>
                <img
                    src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                    style={{ height: 40 }}
                    alt=""
                />
                <Title>
                    Security
                </Title>
            </Structure.Row>
            <div style={{ height: 10 }}/>
            <Structure.LColumn10>
                <Typography.Header>
                    Responsible Disclosure & Bug Bounty
                </Typography.Header>
                <Typography.Paragraph>
                    Last Updated: March 5th, 2025
                </Typography.Paragraph>
                <Typography.Paragraph>
                    We appreciate everyone who attempts to responsibly test Hiyllo's security measures and report any issues to us! You can submit a bug disclosure to Hiyllo by emailing <InlineLink fontSize={12.5} href="mailto:security@hiyllo.com" label="security@hiyllo.com" />
                </Typography.Paragraph>
                <Typography.Paragraph>
                    Eligible bugs will be rewarded with a bounty. Bounties are paid out via the discloser's choice of Venmo, Wire Transfer, or Bitcoin. Payments will be made within 3 business days of disclosure.
                </Typography.Paragraph>
                <Typography.Paragraph>
                    The following domains are eligible for bounties
                </Typography.Paragraph>
                <ul>
                    <li>*.hiyllo.com</li>
                    <li>*.hiyllo.cloud</li>
                    <li>*.hiyllo.network</li>
                    <li>*.hiyllo.work</li>
                    <li>*.hiyllo.business</li>
                </ul>
                <Table header={["Description", "Bounty"]}>
                    <TableRow>
                        <TableCell>
                            <div>
                                Bypass of user authentication controls or <InlineLink href="https://hiyllowork.hiyllo.support/article/f47c6889-6c77-4df0-8889-d1f4dbc5e4c2" label="Hiyllo API" fontSize={12.5}/> security controls 
                            </div>
                        </TableCell>
                        <TableCell>
                            $2000 USD
                        </TableCell>                            
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Vulnerability that exposes confidential customer or internal Hiyllo data with zero-access to target organization or Hiyllo internals
                        </TableCell>
                        <TableCell>
                            $1000 USD
                        </TableCell>                            
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Privilege escalation within an organization that allows access to confidential data
                        </TableCell>
                        <TableCell>
                            $750 USD
                        </TableCell>                            
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Kubernetes network penetration, pod takeover, or other Kubernetes security vulnerabilities<sup>3</sup>
                        </TableCell>
                        <TableCell>
                            $750 USD
                        </TableCell>                            
                    </TableRow>                    
                    <TableRow>
                        <TableCell>
                            <div>
                                XSS attack that allows injection and execution of code <b>on Hiyllo systems</b><sup>2</sup> (client or backend)
                            </div>
                        </TableCell>
                        <TableCell>
                            <div>
                                $500 USD<sup>1</sup>
                            </div>
                        </TableCell>                            
                    </TableRow>                    
                    <TableRow>
                        <TableCell>
                            Privilege escalation within an organization <b>without</b> access to confidential data or destructive actions
                        </TableCell>
                        <TableCell>
                            $250 USD
                        </TableCell>                            
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Vulnerabilities that allow impersonation or spoofing of authentic Hiyllo domains
                        </TableCell>
                        <TableCell>
                            $200 USD
                        </TableCell>                            
                    </TableRow> 
                    <TableRow>
                        <TableCell>
                            Vulnerabilities that allow XSS injection of code on external systems (e.g. injecting code into an outgoing email)
                        </TableCell>
                        <TableCell>
                            $150 USD
                        </TableCell>                            
                    </TableRow>
                    {/* <TableRow>
                        <TableCell>
                            Missing security best practice without a direct/demonstrable attack vector
                        </TableCell>
                        <TableCell>
                            $50 USD
                        </TableCell>                            
                    </TableRow>    */}
                </Table>
                <div>
                    <sup>1</sup>If attack also qualifies/enables a higher tier vulnerability, this amount will be awarded in addition to the higher tier
                </div>
                <div>
                    <sup>2</sup>Injected code must have an attack vector. For example, injecting and executing JavaScript code into a sandboxed enviroment where there is no attack potential does not qualify.
                </div>
                <div>
                    <sup>3</sup>Security vulnerabilities directly related to Kubernetes shall be considered if valid even if they are proven on a staging cluster
                </div>

                <Typography.SubHeader>
                    Exclusions
                </Typography.SubHeader>
                <ul style={{margin:0}}>
                    <li>The transmission (but not execution) of malware infected files over Hiyllo systems, this includes external users emailing malicious attachments to Hiyllo customers (unless the malicious attachment has an attack vector without the customer downloading it)</li>
                    <li>
                        Lack of DKIM, SPF, or DMARC on emails sent from Hiyllo domains is not eligible for a bounty
                    </li>
                    <li>
                        Any subdomain explicitly marked "dev", "development", "test", or "staging" is not eligible for a bounty
                    </li>                
                </ul>

                <Typography.SubHeader>
                    Rules / Disqualifications
                </Typography.SubHeader>
                <ul style={{margin:0}}>
                    <li>
                        Accessing Hiyllo internal data or Hiyllo customer data or that is not your own or on your own test organization is strictly prohibited
                    </li>
                    <li>
                        Submit only one vulnerability at a time unless vulnerabilities are chained together to demonstrate impact.
                    </li>
                    <li>
                        If multiple entities submit the same vulnerability, only the first to submit will be eligible for a bounty.
                    </li>
                    <li>
                        DoS (Denial of Service) / DDoS (Distributed Denial of Service) attacks are not eligible for a bounty.
                    </li>
                    <li>
                        You may not disclose a reported vulnerability publicly until we have confirmed the issue is resolved
                    </li>
                    <li>
                        Infrastructure attacks should be performed 
                    </li>
                </ul>
            </Structure.LColumn10>
        </Container>
    );
});