/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import { Group, Mesh, Object3DEventMap } from 'three';
import * as THREE from 'three';

const Model = React.memo(function Model(): JSX.Element {
  // Reference to the group containing the loaded model
  const group = useRef<Group<Object3DEventMap> | null>(null);

  // Load the GLB model; update the path to your .glb file as needed.
  const { scene } = useGLTF('/hiyllo.glb');
  const matcapTexture = useTexture('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/materials/metal-shiny/metal_shiny.jpg');

  React.useEffect(() => {
    console.log('>>> traverse')
    scene.traverse((child) => {
      // Use the type guard 'instanceof Mesh' to ensure the child is a mesh
      if (child instanceof Mesh) {
        // Dispose of the old material if it exists
        if (child.material) child.material.dispose();

        child.rotateX(0.38);
        child.rotateY(-0.31);
        child.rotateZ(-0.28);

        child.translateZ(0.42);
        child.translateX(0.05);

        // Apply a new material
        child.material = new THREE.MeshMatcapMaterial({
          matcap: matcapTexture,
          flatShading: true,
          // Optionally, adjust other properties here (e.g., color, flatShading, etc.)
        });
      }
    });

    // scene.add(
    //   new THREE.AxesHelper(20)
    // )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  // Rotate the model slowly around the Y-axis on every frame.
  const frames = React.useRef<number>(0);
  React.useEffect(() => {
    console.log('>>> mounting' )
    let animationFrameId: number;
    let lastTime = performance.now();

    const animate = (time: number) => {
      const delta = (time - lastTime) / 1000;
      lastTime = time;
      frames.current++;

      if (group.current) {
        group.current.rotation.y += Math.max(delta / 2, delta * 0.75 * (800 / frames.current));
      }

      animationFrameId = window.requestAnimationFrame(animate);
    };

    animationFrameId = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <group ref={group} dispose={null}>
      {/* Render the loaded GLB scene */}
      <primitive object={scene} />
    </group>
  );
});

export const Logo = React.memo(function Logo() {
  return (
    <Canvas camera={{ position: [0, 0, 1.5] }} style={{height:"100%", width:"100%"}} performance={{min: 0.25}}>
      {/* Add ambient and directional lighting */}
      {/* <ambientLight intensity={1} />
      <directionalLight position={[0, 0, 0]} intensity={1} /> */}
      {/* <HeadLight/> */}

      {/* Render the GLB model */}
      <Model />

      {/* Optional: add OrbitControls to inspect the model */}
      {/* <OrbitControls /> */}
    </Canvas>
  );
});
