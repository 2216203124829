import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { Structure } from "@hiyllo/ux/structure";
import moment from "moment";
import { Helmet } from "react-helmet";

const Container = styled("div", ({$theme}) => ({
    padding: 50,
    backgroundColor: $theme.background1,
    height: "calc(100% - 100px)",
    overflowY: "auto",
    color: $theme.foreground,
    fontFamily: "hiyllo"
}));

const Title = styled("div", ({$theme}) => ({
    fontFamily: "hiyllo",
    fontSize: 37.5,
    fontWeight: "bold",
    color: $theme.foreground,
}));

const ArticleTitle = styled("div", ({$theme}) => ({
    marginTop: 10,
    fontFamily: "hiyllo",
    fontSize: 125,
    fontWeight: "bold",
    color: $theme.foreground,
}));

const Content = styled("div", ({$theme}) => ({
    fontSize: 17.5,
    display: "flex",
    flexDirection: "column",
    gap: 15,
}));

export const BlogFrame = React.memo(function BlogFrame(props: React.PropsWithChildren<{
    articleId: string;
    articleTitle: string;
    description: string;
    date: string;
}>) {
    return (
        <>
            <Helmet>
                <title>{props.articleTitle}</title>
                <meta name="description" content={props.description} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={"https://www.hiyllo.com/blog/" + props.articleId} />
                <meta property="og:title" content={props.articleTitle} />
                <meta property="og:description" content={props.description} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={"https://www.hiyllo.com/blog/" + props.articleId} />
                <meta property="og:site_name" content="Hiyllo Blog" />
                <meta name="twitter:card" content="summary_large" />
                <meta name="twitter:title" content={props.articleTitle} />
                <meta name="twitter:description" content={props.description} />
                <meta name="twitter:site" content="@hiylloinc" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "headline": props.articleTitle,
                        "description": props.description,
                        "image": "https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png",
                        "datePublished": props.date,
                        "author": {
                            "@type": "Organization",
                            "name": "Hiyllo Inc.",
                            "url": "https://www.hiyllo.com",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                            }
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Hiyllo Inc.",
                            "url": "https://www.hiyllo.com",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <Container>
                <Structure.Row>
                    <img
                        src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                        style={{ height: 40 }}
                        alt=""
                    />
                    <Title>
                        Hiyllo Blog
                    </Title>
                </Structure.Row>
                <ArticleTitle>
                    {props.articleTitle}
                </ArticleTitle>
                <div style={{ opacity: 0.75 }}>
                    {moment(props.date, "YYYY/MM/DD").format("MMM Do, YYYY")}
                </div>
                <div style={{ height: 20 }}/>
                <Content>
                    {props.children}
                </Content>
            </Container>
        </>
    );
});