import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { Structure } from "@hiyllo/ux/structure";
import { Typography } from "@hiyllo/ux/typography";
import { Table, TableRow, TableCell } from "@hiyllo/ux/table";
import { InlineLink } from "@hiyllo/ux/inline-link";

const Container = styled("div", ({$theme}) => ({
    padding: 50,
    width: "calc(100% - 100px)",
    backgroundColor: $theme.background1,
    height: "calc(100% - 100px)",
    overflowY: "auto",
    color: $theme.foreground,
    fontFamily: "hiyllo"
}));

const Title = styled("div", ({$theme}) => ({
    fontFamily: "hiyllo",
    fontSize: 37.5,
    fontWeight: "bold",
    color: $theme.foreground,
}));

export const TrustPage = React.memo(function TrustPage() {
    return (
        <Container>
            <Structure.Row>
                <img
                    src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
                    style={{ height: 40 }}
                    alt=""
                />
                <Title>
                    Hiyllo Trust Center
                </Title>
            </Structure.Row>
            <div style={{ height: 10 }}/>
            <Structure.Flex $direction="row" $align="start" $justify="start" $gap={50}>
                <div style={{flexGrow:1}}>
                    <Structure.LColumn10>
                        <Typography.Paragraph>
                            Last Updated: May 27th, 2025
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Hiyllo is SOC 2 Type 1 compliant for Security, Availability, and Confidentiality. <InlineLink href="/trust/soc2-type1-attestation.pdf" label="You can access a confirmation of our attestation report here" fontSize={15}/>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Our full SOC 2 report is confidential and may only be shared under NDA, if you&apos;d like to access a copy please email <InlineLink href="mailto:compliance@hiyllo.com" label="compliance@hiyllo.com" fontSize={15}/>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Our SOC 2 report covers our main Hiyllo Work product, as well as associated secondary services (such as Hiyllo Identity).
                        </Typography.Paragraph>
                    </Structure.LColumn10>
                </div>
                <div>
                    <img src="/assurancelab-soc.png" style={{width:200}} />
                </div>
            </Structure.Flex>
        </Container>
    );
});