import { styled } from "@hiyllo/ux/styled";

export const CodeBlock = styled("div", ({$theme}) => ({
    background: $theme.background2,
    padding: 10,
    borderRadius: 5,
    fontFamily: "JetBrains Mono, monospace",
    fontSize: 15,
}));

export const InnerContent = styled("div", ({$theme}) => ({
    display: "flex",
    flexDirection: "column",
    gap: 10,
}));