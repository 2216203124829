import React from "react";
import { Typography } from "@hiyllo/ux/typography";
import { InlineLink } from "@hiyllo/ux/inline-link";

import { BlogFrame } from "../frame";
import { InnerContent } from "../components/code-block";

export const Blog_JSApi = React.memo(function Blog_JSApi() {
    return (
        <BlogFrame
            articleTitle="Introducing the Hiyllo JavaScript API"
            date="2025/02/09"
            description="Hiyllo's official JavaScript API package is now available, allowing integration with Hiyllo Cloud and Hiyllo Work."
            articleId="js-api"
        >
            <InnerContent>
                <div>
                    This month we&apos;re releasing our official JavaScript API package for Hiyllo. This package allows integration with Hiyllo Cloud (our centralized auth and federation service) and Hiyllo Work using organization-level API keys.
                </div>
                <div>
                    Full details for setup and usage in available in <InlineLink fontSize={17.5} href="https://www.npmjs.com/package/@hiyllo/api" label="the README file on npm"/>, but in this article we&apos;ll go over some of the decisions we made and any details that we personally find a bit more interesting!
                </div>
            </InnerContent>
            <InnerContent>
                <Typography.Header>Asymetric API Keys</Typography.Header>
                <div>
                    Rather than using tokens, we utilize private keys as our API keys. These private keys are generated in the browser when the user creates an API key, and only the public key gets sent to Hiyllo Servers. Specifically, we utilize a 4096-bit RSA private key, and every call to the API gets signed with the private key, and we send the signature (along with a key ID that is embedded in the API key string) in our request. Before we sign the request data, we also append a epoch timestamp to prevent replay attacks.
                </div>
                <div>
                    The benefit of this system is that the API key never leaves the hands of the user (or their servers). While there is an added overhead of signing the request, we believe that this is a fair tradeoff for the added security, especially given the Hiyllo API is called less frequently than the average API (such as autocomplete APIs, notification APIs, etc).
                </div>
            </InnerContent>
            <InnerContent>
                <Typography.Header>Future plans for OAuth</Typography.Header>
                <div>
                    Our current API scheme only supports generating API keys from an organization, so it doesn't offer the ideal environment for third-party developers to integrate with Hiyllo. Out plans are to add OAuth support in the future, but we wanted to get this API out as soon as possible, so we decided to release it with the current system. When we do add OAuth, we'll keep the scheme exactly the same. After OAuth authentication is complete, the third-party server will get an API key with the same structure scoped to the authenticated federation.
                </div>
            </InnerContent>       
            <InnerContent>
                <Typography.Header>Future plans for new methods</Typography.Header>
                <div>
                    We've added a relatively small set of methods to the API, mostly since those were the ones requested by our customers. We'll be adding more slowly, but if you're a Hiyllo Work customer or intersted developer you can email us at support@hiyllo.com to request new ones.
                </div>
            </InnerContent>            
            <InnerContent>
                <Typography.Header>Support</Typography.Header>
                <div>
                    We believe our API is a part of our product just as much as the app itself, so we offer support for it just like we do for the app. If you have any questions or need help, please email us at support@hiyllo.com
                </div>
            </InnerContent>            
        </BlogFrame>
    );
});